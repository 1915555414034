<template>
    <rsck-page :show-page-bottom-button="true">
        <rsck-refresh v-if="showResult" ref="refresh" @onPullDown="onPullDown" @onPullUp="onPullUp"
                      :height="scrollHeight">
            <div class="page-header">
                <div class="village-info" @click="showVillage">
                    <div class="village-name">{{villageName}}</div>
                    <div class="icon">
                        <img src="https://api.mengjingloulan.com/storage/wechat/services/down.png"/>
                    </div>
                </div>
                <div class="search-container">
                    <search-v1
                            class="search"
                            v-model="search"
                            :isDisabled="true"
                            @onClick="goSearch"
                    />
                </div>
            </div>

            <div class="swiper-container">
                <nut-swiper
                        class="swiper"
                        direction="horizontal"
                        :swiperData="banners"
                        :loop="true"
                >
                    <div
                            v-for="(item, index) in banners"
                            :key="index"
                            class="nut-swiper-slide"
                    >
                        <img :src="item.img_path" style="width: 100%; height: 100%"/>
                    </div>
                </nut-swiper>
            </div>

            <rsck-title :title="cardTitle"></rsck-title>

            <div class="goods-list">
                <goods-card
                        v-for="(item,index) in dataList"
                        :key="index"
                        class="goods"
                        :goods="item"
                        @goToGoods="goToGoods"
                        :width="goodsWidth"
                />
            </div>

        </rsck-refresh>

        <div class="page-bottom-inner-container" slot="pageBottomButtons">
            <div class="left" @click="goToMyService">
                <div class="img">
                    <img src="https://api.mengjingloulan.com/storage/wechat/services/my-services.png"/>
                </div>
                我的发布
            </div>
            <div class="line"></div>
            <div class="right" @click="goToAddService">
                <div class="img">
                    <img src="https://api.mengjingloulan.com/storage/wechat/services/add-services.png"/>
                </div>
                发布{{listTypeId == 1 ? '商品' : '服务'}}
            </div>
        </div>

        <village-choose ref="village" :options="villageList" v-if="villageList.length > 0"
                        @chooseVillage="chooseVillage" :show-choose-all="true"></village-choose>
    </rsck-page>

</template>

<script>
  import RsckRefresh from '@/components/common/rsck-refresh'
  import SearchV1 from '@/template/community/search/searchV1'
  import RsckTitle from '@/components/common/rsck-title'
  import RsckPage from '@/components/common/rsck-page'
  import GoodsCard from '@/template/services/goods-card'
  import VillageChoose from '@/pages/services/components/village-choose'

  export default {
    name: 'home',
    components: {VillageChoose, GoodsCard, RsckPage, RsckTitle, SearchV1, RsckRefresh},
    data () {
      return {
        banners: [],
        scrollHeight: 0,
        showResult: false,
        listTypeId: 1,
        villageId: 0,
        page: 1,
        pageSize: 4,
        keyword: '',
        hasMore: true,
        dataList: [],
        villageName: '全部',
        villageList: [],
        goodsWidth: 0,
        canContinue: true,
        search: '',
      }
    },
    mounted () {
      document.title = ''
      this.scrollHeight = window.innerHeight - document.querySelector('.services-page-bottoms').clientHeight - 30
      console.log('this.scrollHeight', this.scrollHeight)
      let query = this.$route.query
      this.listTypeId = query?.listTypeId || 1
      if (this.listTypeId == 1) {
        document.title = '闲置二手'
      } else if (this.listTypeId == 2) {
        document.title = '社区服务'
      }

      this.goodsWidth = (window.innerWidth - 40) / 2

      this.init()
      this.getVillageList()
    },
    computed: {
      cardTitle () {
        if (this.listTypeId == 1) {
          return '闲置优品'
        } else if (this.listTypeId == 2) {
          return '精选服务'
        }
      }
    },
    methods: {
      goSearch () {
        let query = {
          listTypeId: this.listTypeId,
        }
        query = encodeURIComponent(JSON.stringify(query))
        this.$platform.wxsdk.wxRoute({
          type: 'navigateTo',
          url: '/web/services/search?query=' + query,
        })
      },
      onPullDown () {
        this.init()
        this.$refs.refresh.endPullDown()
      },
      onPullUp () {
        this.showResult = true
        this.getGoodsList().then(response => {
          this.$refs.refresh.endPullUp(!this.hasMore)
        })
      },
      init () {
        //初始化页面请求这些接口，请求完成后，将页面的上拉和下拉状态结束
        this.getBanner()
        this.page = 1
        this.dataList = []
        this.hasMore = true
        this.getGoodsList().then(() => {
          setTimeout(() => {
            this.showResult = true
            if (this.$refs?.refresh) {
              this.$refs.refresh.endPullUp(!this.hasMore)
            }
          }, 100)
        })
      },
      getBanner () {
        this.$api.services.getSwiperList().then(response => {
          this.banners = response.data
        })
      },
      getGoodsList () {
        return new Promise((resolve, reject) => {
          if (this.hasMore) {
            this.$api.services.goodsList({
              page: this.page,
              page_size: this.pageSize,
              village_id: this.villageId,
              keyword: this.keyword,
              list_type_id: this.listTypeId,
            }).then(response => {
              let data = response.data
              if (data.length < this.pageSize) {
                this.hasMore = false
              }
              data.forEach(item => {
                item.cover = item?.cover_path || ''
                item.tag = item?.class_name || ''
                item.deduct = item?.deduct_renshengquan || 0
                item.market_price = item?.price || 0
                this.dataList.push(item)

              })

              this.page++

              resolve()
            }).catch(error => {
              this.$toast.fail(error.message)
              reject(error)
            })
          }
        })
      },
      getVillageList () {
        this.$api.services.getVillageList({list_type_id: this.listTypeId}).then(response => {
          this.villageList = response.data
          this.$forceUpdate()
        })
      },
      showVillage () {
        this.$refs.village.show()
      },
      chooseVillage (item) {
        this.villageId = item.value
        this.villageName = item.name
        this.$forceUpdate()
        this.init()
      },
      goToGoods (goods) {
        if (this.canContinue) {
          this.canContinue = false
          let query = {
            listTypeId: this.listTypeId,
            id: goods.id
          }
          query = encodeURIComponent(JSON.stringify(query))
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/services/goods?query=' + query,
          })
          setTimeout(() => {
            this.canContinue = true
          }, 500)
        }

      },
      goToMyService () {
        if (this.canContinue) {
          this.canContinue = false
          let query = {
            listTypeId: this.listTypeId,
          }
          query = encodeURIComponent(JSON.stringify(query))
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/services/my-services?query=' + query,
          })

          setTimeout(() => {
            this.canContinue = true
          }, 500)
        }

      },
      goToAddService () {
        if (this.canContinue) {
          this.canContinue = false

          let query = {
            listTypeId: this.listTypeId,
          }
          query = encodeURIComponent(JSON.stringify(query))
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/services/add-services?query=' + query,
          })

          setTimeout(() => {
            this.canContinue = true
          }, 500)
        }

      }
    }
  }
</script>

<style scoped lang="scss">
    .page-header {
        width: 100%;
        display: flex;

        .village-info {
            flex: 1;
            display: flex;
            height: 36px;
            margin-right: 7px;

            .village-name {
                flex: 1;
                font-size: 14px;
                line-height: 36px;
                color: #333333;
                font-weight: bold;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                word-break: break-all;
                margin-right: 3px;
                text-align: left;
            }

            .icon {
                width: 15px;
                display: flex;
                align-items: center;

                img {
                    width: 12px;
                    height: 12px;
                }
            }
        }

        .search-container {
            width: 256px;

            .search {
                height: 36px;
                -webkit-border-radius: 36px;
                -moz-border-radius: 36px;
                border-radius: 36px;
                background: #EFEFEF;
            }
        }
    }

    .swiper-container {
        height: 122px;
        margin-top: 10px;

        .swiper {
            height: 122px;
        }
    }

    .page-bottom-inner-container {
        width: 100%;
        display: flex;

        .left {
            height: 22px;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: #F46A17;
            font-family: PingFangSC-Regular, PingFang SC;

            .img {
                width: 16px;
                height: 16px;
                margin-right: 3px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .line {
            height: 22px;
            width: 1px;
            border-left: 1px solid #EBEBEB;
        }

        .right {
            height: 22px;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: #F46A17;
            font-family: PingFangSC-Regular, PingFang SC;

            .img {
                width: 16px;
                height: 16px;
                margin-right: 3px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .goods-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .goods {
            margin-bottom: 10px;
        }
    }
</style>