<template>
    <div class="card-titles">
        <div class="image">
            <img src="../../static/image/icons/hotGoods.png">
        </div>
        <div class="title-text" slot="title">{{title}}</div>
    </div>
</template>

<script>
  export default {
    name: 'rsck-title',
    props: {
      title: {
        type: String,
        default: '热销商品'
      }
    }
  }
</script>

<style scoped lang="scss">
    .card-titles {
        width: 100%;
        height: 30px;
        display: flex;
        margin-top: 15px;
        align-items:center;

        .image {

            img {
                width: 24px;
                height: 24px;
            }
        }

        .title-text {
            flex: 1;
            display: flex;
            height: 24px;
            line-height: 24px;
            align-items: center;
            font-size: 14px;
            color: #333;
            font-weight: bold;
            font-family: PingFangSC-Semibold, PingFang SC;
        }
    }
</style>